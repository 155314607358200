import API from "@/api";

const state = {
  markSeenNotificationsIds: [],
  sendNotificationSeenQueue: [],
  notSeenNotifications: [],
  seenNotifications: [],
  lastPage: 1,
  isLoading: false,
  totalNotView: 0,
  isProcessing: false,
  firstTimeOfLastNotificationReceived: "",
  timeOfLastNotificationReceived: "",
};

const actions = {
  mapNotifications(notifications) {
    if (notifications?.length > 0) {
      return notifications.map((notification) => {
        return {
          id: notification.id,
          title: notification.title,
          description: notification.description,
          date: notification.sent_at,
          buttonTitle: notification.button_label,
          type: notification.type,
          buttonLink: notification.button_link,
          isShowed: notification.is_showed,
          group: notification.group,
        };
      });
    }
    return [];
  },

  async fetchTotalNotViewCount({ commit }) {
    try {
      const params = {
        is_showed: 0,
      };
      const response = await API.get("api/auth/notifications", { params });
      commit("SET_TOTAL_NOT_VIEW", response.data?.meta?.total);
    } catch (e) {
      throw Error(e);
    }
  },
  async getAllNotifications({ commit, state }) {
    commit("SET_LOADING", true);

    try {
      let params = {};
      if (state.timeOfLastNotificationReceived) {
        const date = new Date(state.timeOfLastNotificationReceived);
        date.setSeconds(date.getSeconds() - 1);
        const isoString = date.toISOString().split(".")[0];
        params = {
          last_updated_at: isoString,
        };
      }
      const response = await API.get("api/auth/notifications", { params });
      const mappedNotifications = actions.mapNotifications(response.data?.data);
      const notSeen = mappedNotifications.filter((notify) => !notify.isShowed);
      commit("ADD_NOT_SEEN_NOTIFICATIONS", notSeen);
      const seen = mappedNotifications.filter((notify) => notify.isShowed);
      commit("ADD_SEEN_NOTIFICATIONS", seen);

      const time = mappedNotifications[mappedNotifications.length - 1]?.date;
      if (!state.timeOfLastNotificationReceived) {
        commit("SET_FIRST_TIME_OF_LAT_NOTIFICATION_RECEIVED", time);
      }
      commit("SET_TIME_OF_LAT_NOTIFICATION_RECEIVED", time);

      commit("SET_LAST_PAGE", response.data?.meta.last_page);
    } catch (e) {
      throw Error(e);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async markAsRead({ commit }, notificationId) {
    try {
      await API.put(`api/auth/notifications/view/${notificationId}`);
    } catch (e) {
      throw Error(e);
    }
  },

  async markAsReadAll({ commit, state }) {
    const copy = [...state.notSeenNotifications];
    copy
      .slice()
      .reverse()
      .forEach((notification) =>
        commit("MARK_NOTIFICATION_AS_SEEN", notification.id)
      );
    commit("SORT_SEEN_NOTIFICATIONS");
    commit("SET_TOTAL_NOT_VIEW", 0);
    try {
      const params = {
        last_updated_at: state.firstTimeOfLastNotificationReceived,
      };
      await API.put(`api/auth/notifications/view-all`, {}, { params });
    } catch (e) {
      throw Error(e);
    }
  },
  markNotificationsAsSeen({ commit }) {
    const copy = state.notSeenNotifications.filter((notify) =>
      state.markSeenNotificationsIds.includes(notify.id)
    );

    copy
      .slice()
      .reverse()
      .forEach((notification) =>
        commit("MARK_NOTIFICATION_AS_SEEN", notification.id)
      );
    commit("SORT_SEEN_NOTIFICATIONS");
    commit("CLEAR_MARK_NOTIFICATIONS_ID");
  },
  async processMarkAsReadQueue({ state, commit }) {
    if (state.isProcessing) return;
    commit("SET_PROCESSING", true);

    while (state.sendNotificationSeenQueue.length > 0) {
      const notificationId = state.sendNotificationSeenQueue[0];
      commit("SHIFT_QUEUE_NOTIFICATIONS");
      try {
        await API.put(`api/auth/notifications/view/${notificationId}`);
      } catch (e) {
        throw Error(`Ошибка при отправке ID ${notificationId}:${e}`);
      }
    }

    commit("SET_PROCESSING", false);
  },

  markNotification({ commit, dispatch }, notificationId) {
    commit("ADD_MARK_NOTIFICATION_ID", notificationId);
    commit("ADD_QUEUE_NOTIFICATION_ID", notificationId);
    commit("SET_TOTAL_NOT_VIEW", state.totalNotView - 1);
    dispatch("processMarkAsReadQueue");
  },
};

const mutations = {
  SHIFT_QUEUE_NOTIFICATIONS(state) {
    state.sendNotificationSeenQueue.shift();
  },
  ADD_MARK_NOTIFICATION_ID(state, notificationId) {
    state.markSeenNotificationsIds.push(notificationId);
  },
  ADD_QUEUE_NOTIFICATION_ID(state, notificationId) {
    state.sendNotificationSeenQueue.push(notificationId);
  },
  CLEAR_MARK_NOTIFICATIONS_ID(state) {
    state.markSeenNotificationsIds = [];
  },
  ADD_NOT_SEEN_NOTIFICATIONS(state, notifications) {
    state.notSeenNotifications.push(...notifications);
  },
  ADD_SEEN_NOTIFICATIONS(state, notifications) {
    state.seenNotifications.push(...notifications);
  },
  SET_LAST_PAGE(state, page) {
    state.lastPage = page;
  },
  SET_TIME_OF_LAT_NOTIFICATION_RECEIVED(state, time) {
    state.timeOfLastNotificationReceived = time;
  },
  SET_FIRST_TIME_OF_LAT_NOTIFICATION_RECEIVED(state, time) {
    state.firstTimeOfLastNotificationReceived = time;
  },
  MARK_NOTIFICATION_AS_SEEN(state, notificationId) {
    const index = state.notSeenNotifications.findIndex(
      (notification) => notification.id === notificationId
    );

    if (index !== -1) {
      const [notification] = state.notSeenNotifications.splice(index, 1);
      notification.isShowed = true;
      state.seenNotifications.unshift(notification);
    }
  },
  SORT_SEEN_NOTIFICATIONS(state) {
    state.seenNotifications.sort((a, b) => new Date(b.date) - new Date(a.date));
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_PROCESSING(state, payload) {
    state.isProcessing = payload;
  },
  SET_TOTAL_NOT_VIEW(state, payload) {
    state.totalNotView = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
