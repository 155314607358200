<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "Notification",
  components: { MainButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    iconClass: {
      validator(value) {
        return value === null || typeof value === "string";
      },
      required: true,
    },
    buttonTitle: {
      validator(value) {
        return value === null || typeof value === "string";
      },
      default: null,
    },
    buttonLink: {
      type: String,
      default: null,
    },
    //s m
    buttonSize: {
      type: String,
      default: "s",
    },
  },
};
</script>

<template>
  <div class="notification">
    <div class="notification__icon">
      <img
        v-if="!iconClass"
        src="../../assets/icons/rc_notification.svg"
        alt="Иконка уведомления"
      />
      <span v-else :class="iconClass"></span>
    </div>
    <div class="notification__right-section">
      <div class="notification__right-section__text">
        <h4 class="notification__right-section__text-title">
          {{ title }}
        </h4>
        <div class="notification__right-section__text__body">
          <p
            class="notification__right-section__text__body-description"
            v-html="description"
          ></p>
          <time class="notification__right-section__text__body-time">
            {{ date }}
          </time>
        </div>
      </div>
      <MainButton
        v-if="buttonTitle"
        :class="['notification__right-section__button', buttonSize]"
        :title="buttonTitle"
        color="transparent"
        @click="$emit('onButtonClick')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification {
  display: flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 16px;
  background: #fff;
  width: 100%;

  &__icon {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 21.667px;
    background: #fff0c7;

    > span {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      background-color: #212121;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;

      &-title {
        @include body-1-bold;
        color: var(--text-dark-primary, #212121);
        font-variant-numeric: lining-nums proportional-nums;
        align-self: stretch;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch;
        word-break: break-word;
        &-description {
          @include text-2;
          color: #212121;
          font-variant-numeric: lining-nums proportional-nums;
          align-self: stretch;

          :deep(.ql-align-center) {
            text-align: center;
          }

          :deep(.ql-align-right) {
            text-align: right;
          }

          :deep(.ql-align-justify) {
            text-align: justify;
          }

          :deep([class*="ql-indent-"]) {
            @for $i from 1 through 8 {
              &.ql-indent-#{$i} {
                margin-left: 20px * $i;
              }
            }
          }

          :deep(ol) {
            list-style: none;
            > li[data-list="bullet"] {
              list-style-type: disc;
            }

            @for $level from 1 through 8 {
              > li[data-list="ordered"].ql-indent-#{$level}:first-child {
                counter-reset: level-#{$level};
              }
            }
            > li[data-list="ordered"]:not([class*="ql-indent-"]) {
              counter-reset: level-0 1;
              ::before {
                content: counter(level-0, decimal) ". ";
              }
            }

            @for $level from 1 through 8 {
              @if ($level % 3 == 0) {
                > li[data-list="ordered"].ql-indent-#{$level} {
                  counter-increment: level-#{$level};
                  ::before {
                    content: counter(level-#{$level}, decimal) ". ";
                  }
                }
              }
              @if ($level % 3 == 1) {
                > li[data-list="ordered"].ql-indent-#{$level} {
                  counter-increment: level-#{$level};
                  ::before {
                    content: counter(level-#{$level}, lower-alpha) ". ";
                  }
                }
              }
              @if ($level % 3 == 2) {
                > li[data-list="ordered"].ql-indent-#{$level} {
                  counter-increment: level-#{$level};
                  ::before {
                    content: counter(level-#{$level}, lower-roman) ". ";
                  }
                }
              }
            }
          }
        }

        &-time {
          @include caption-1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: #666;
          font-variant-numeric: lining-nums proportional-nums;
          text-overflow: ellipsis;
        }
      }
    }

    &__button {
      display: flex;
      height: 32px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid #969696;
      width: fit-content;

      &.s {
        padding: 6px 16px;
      }
      &.m {
        padding: 8px 24px;
      }
      @media (hover: hover) {
        &:hover {
          background: #f3f3f3;
        }
      }
    }
  }
}
</style>
