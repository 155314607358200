<script>
import vClickOutside from "v-click-outside";
import MainObserver from "@/components/helpers/MainObserver.vue";
import MainSearch from "@/components/helpers/MainSearch.vue";

// TODO: переписать min-width

export default {
  components: { MainSearch, MainObserver },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    selectedOption: {
      type: Object,
    },

    options: {
      type: Array,
      default: () => [],
    },

    styleType: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    isSetDefault: {
      type: Boolean,
      default: true,
    },

    caption: {
      type: String,
      default: "",
    },

    useObserver: {
      type: Boolean,
      default: false,
    },

    useSearch: {
      type: Boolean,
      default: false,
    },

    disable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showOptions: false,
      searchValue: "",
    };
  },

  watch: {
    showOptions(newValue) {
      if (!newValue) {
        this.clear();
      }
    },
  },

  computed: {
    formattedSelectorClass() {
      return this.styleType ? `main-select_${this.styleType}` : "";
    },

    showSearch() {
      return this.options.length > 10 || this.searchValue.length > 0;
    },

    emptyList() {
      return this.searchValue.length > 0 && this.options.length === 0;
    },
  },

  methods: {
    hideItems() {
      this.showOptions = false;
    },

    selectItem(item) {
      if (!!item.disabled) {
        this.showOptions = false;
        return;
      }

      this.$emit("update:selectedOption", item);

      setTimeout(() => {
        this.showOptions = false;
      });
    },

    clear() {
      this.searchValue = "";
      this.$emit("search", "");
    },
  },

  mounted() {
    if (
      this.selectedOption === null &&
      !this.options[0]?.disabled &&
      this.isSetDefault
    ) {
      this.$emit("update:selectedOption", this.options[0]);
    }
  },
};
</script>

<template>
  <div
    :class="[
      { 'main-select-wrapper': title },
      { 'main-select_disable': disable },
    ]"
  >
    <div v-if="title" class="main-select__title">
      {{ title }}
    </div>

    <div
      v-click-outside="hideItems"
      class="main-select"
      :class="formattedSelectorClass"
      @click="showOptions = !showOptions"
    >
      <span v-if="selectedOption" class="main-select__selected-item">
        {{ selectedOption.name }}
      </span>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 2.5L4 5.5L7 2.5" stroke="#BDBDBD" />
      </svg>

      <div v-if="showOptions" class="main-select__items items" @click.stop>
        <MainSearch
          v-if="showSearch && useSearch"
          v-model="searchValue"
          placeholder="Поиск"
          :is-show-button-search="false"
          @input="$emit('search', searchValue)"
          @search="$emit('search', searchValue)"
          @clear="clear"
        />

        <div
          class="items__wrapper"
          :class="{ items__wrapper_search: showSearch }"
        >
          <div
            v-for="(option, i) in options"
            :key="i"
            class="main-select__item"
            :class="{ 'main-select__item_disabled': option.disabled ?? false }"
            @click="selectItem(option)"
          >
            {{ option.name }}
          </div>

          <MainObserver
            v-if="useObserver && searchValue === ''"
            @intersect="$emit('intersect')"
          />

          <p v-if="emptyList">Ничего не найдено</p>
        </div>
      </div>
    </div>

    <p v-if="caption" class="main-select__caption">{{ caption }}</p>
  </div>
</template>

<style scoped lang="scss">
.main-select-wrapper {
  position: relative;

  .main-select__title {
    display: block;
    margin-bottom: $space-s;
    @include text-2;
    color: $light-sixth;
  }
}

.main-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  @include text-1;
  color: $dark-primary;

  &__selected-item {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > svg {
    margin: 0 0 0 $space-s;
  }

  &__caption {
    margin-top: 8px;
    margin-left: 4px;
    @include caption-1;
    color: $dark-sixth;
  }

  .items {
    position: absolute;
    top: calc(100% + 4px);
    background-color: $background-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 999;

    &__wrapper {
      max-height: 240px;
      overflow: auto;

      &_search {
        max-height: 200px;
      }

      p {
        padding: 8px 16px;
        @include text-2;
        color: $dark-fifth;
      }
    }
  }

  .main-search {
    padding: 0 16px;
    margin-bottom: 8px;

    &::v-deep .input {
      height: 44px !important;
    }
  }

  &__item {
    @include text-2;
    padding: 8px 16px;
    word-break: break-word;

    &:hover {
      background-color: $light-second;
    }

    &:active {
      background-color: $background-white;
    }

    &_disabled {
      cursor: default;
      color: $light-sixth;
    }
  }

  &_background-gray {
    min-width: 130px;
    height: 48px;
    background-color: $background-grey;
    border: 1px solid $light-fifth;
    border-radius: $space-s;
    padding: 0 $space-m;
    @include text-2;
    color: $dark-fifth;

    > svg {
      margin: 0 0 0 auto;
    }

    .main-select__items {
      width: calc(100% + 2px);
      left: -1px;
    }
  }

  &_background-white {
    min-width: 130px;
    max-width: 210px;
    height: 48px;
    background-color: $background-white;
    border: 1px solid $light-fifth;
    border-radius: $space-s;
    padding: 0 $space-m;
    @include text-2;
    color: $dark-primary;

    > span {
      margin-right: 8px;
    }

    > svg {
      margin: 0 0 0 auto;
    }

    .main-select__items {
      max-width: 210px;
      width: calc(100% + 2px);
      left: -1px;
    }
  }

  &_disable {
    opacity: 0.7;
    pointer-events: none;
  }
}

.observer {
  height: 2px !important;
}
</style>
