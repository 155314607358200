var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications scrollable"},[(_vm.isMobile)?_c('div',{staticClass:"notifications-list__sub-header"},[_c('MainButtonIcon',{staticClass:"notifications-list__sub-header__back-button",attrs:{"aria-label":"Назад"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"icon-chevron-left"})]),_c('h2',{staticClass:"notifications-list__sub-header__title"},[_vm._v("Уведомления")]),_c('div',{staticClass:"notifications-list__sub-header__ellipse"},[(_vm.notSeenNotifications.length)?_c('EllipseViewAllButton',{on:{"view-all":function($event){return _vm.$emit('view-all')}}}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"notifications-list scrollable"},[(_vm.notSeenNotifications.length)?_c('div',{staticClass:"notifications-list__not-view"},[_c('div',{staticClass:"notifications-list__not-view__title"},[_c('h3',[_vm._v("НОВЫЕ УВЕДОМЛЕНИЯ")]),(!_vm.isMobile)?_c('EllipseViewAllButton',{on:{"view-all":function($event){return _vm.$emit('view-all')}}}):_vm._e()],1),_vm._l((_vm.notSeenNotifications),function(notification){return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          callback: () => _vm.notificationSeen(notification),
          once: true,
        }),expression:"{\n          callback: () => notificationSeen(notification),\n          once: true,\n        }"}],key:notification.id,staticClass:"notifications-list__not-view__notification"},[_c('Notification',{attrs:{"title":notification.title,"button-title":notification.buttonTitle,"button-link":notification.buttonLink,"description":notification.description,"date":_vm.formatDate(notification.date),"icon-class":_vm.getCurrentIconClass(notification.group),"button-size":_vm.getCurrentNotificationButtonSize},on:{"onButtonClick":function($event){return _vm.openLink(notification.buttonLink)}}})],1)})],2):_vm._e(),(_vm.hasMore)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        callback: _vm.loadMoreIfNotLoading,
        once: false,
      }),expression:"{\n        callback: loadMoreIfNotLoading,\n        once: false,\n      }"}],staticClass:"sentinel"}):_vm._e(),_c('div',{staticClass:"notifications-list__view"},[(_vm.seenNotifications.length)?_c('div',{staticClass:"notifications-list__view__title"},[_c('h3',[_vm._v("ПРОСМОТРЕННЫЕ")])]):_vm._e(),_vm._l((_vm.seenNotifications),function(notification){return _c('div',{key:notification.id,staticClass:"notifications-list__view__notification"},[_c('Notification',{attrs:{"type":notification.type,"title":notification.title,"button-title":notification.buttonTitle,"button-link":notification.buttonLink,"description":notification.description,"date":_vm.formatDate(notification.date),"icon-class":_vm.getCurrentIconClass(notification.group),"button-size":_vm.getCurrentNotificationButtonSize},on:{"onButtonClick":function($event){return _vm.openLink(notification.buttonLink)}}})],1)}),(_vm.hasMore)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          callback: _vm.loadMoreIfNotLoading,
          once: false,
        }),expression:"{\n          callback: loadMoreIfNotLoading,\n          once: false,\n        }"}],staticClass:"sentinel"}):_vm._e()],2),(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c('span',{staticClass:"icon-loader"})]):_vm._e(),(_vm.isEmpty && !_vm.isLoading)?_c('EmptyNotifications'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }