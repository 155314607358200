<script>
import PopoverButton from "@/components/PopoverButton.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "EllipseViewAllButton",
  components: { MainButton, PopoverButton },
  data() {
    return {
      isPopupOpen: false,
    };
  },
  methods: {
    togglePopup() {
      this.isPopupOpen = !this.isPopupOpen;
    },
    sendEmitAndClose() {
      this.$emit("view-all");
      this.togglePopup();
    },
  },
};
</script>

<template>
  <PopoverButton
    :is-popup-open="isPopupOpen"
    button-aria-label="Меню опций"
    @togglePopup="togglePopup"
  >
    <template #default>
      <span class="icon-more-vert-regular"></span>
    </template>
    <template #content>
      <MainButton
        class="check-all-view"
        title="Отметить все как прочитанные"
        color="transparent"
        @click="sendEmitAndClose"
      >
        <template #iconLeft>
          <span class="icon-circle-done-2"></span>
        </template>
      </MainButton>
    </template>
  </PopoverButton>
</template>

<style scoped lang="scss">
.check-all-view {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  white-space: nowrap;
  &:hover {
    background: #f3f3f3;
  }
}

.icon-more-vert-regular {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #666666;
}

.icon-circle-done-2 {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: #212121;
}
</style>
