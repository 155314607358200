<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import Aside from "../aside/Aside";
import MobileHeader from "../MobileHeader";
import MainSelect from "@/components/helpers/MainSelect";
import MainHeader from "@/components/main-header/MainHeader.vue";

export default {
  name: "MainLayout",

  components: {
    MainHeader,
    Aside,
    MobileHeader,
    MainSelect,
  },

  data() {
    return {
      locales: [
        {
          id: "RUB",
          name: "RU",
        },
        {
          id: "KZT",
          name: "KZ",
        },
      ],

      locale: null,
      isMobile: window.innerWidth < 1024,
    };
  },

  watch: {
    locale: {
      deep: true,
      handler() {
        if (this.selectedLocale.id !== this.locale.id) {
          this.setLocale(this.locale);
        }
      },
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedLocale: (state) => state.auth.selectedLocale,
      notification: (state) => state.notification,
      notifications: (state) => state.auth.notifications,
    }),

    ...mapGetters({
      getLocaleAccounts: "auth/getLocaleAccounts",
      getUserAccounts: "auth/getUserAccounts",
    }),

    hasAnotherLocales() {
      return !!this.getUserAccounts.find(
        (account) => account.currency !== "RUB"
      );
    },

    formattedNotice() {
      return {
        title: this.notification?.title || "",
        description: this.notification?.description || "",
        closed: this.notification?.closed || false,
        type: this.notification?.type || "info",
        group: this.notification?.group || "",
      };
    },
  },

  methods: {
    ...mapMutations({
      setLocale: "auth/SET_LOCALE",
      setNotification: "SET_NOTIFICATION",
    }),

    ...mapActions({
      createNotification: "createNotification",
      loadNotifications: "auth/loadNotifications",
      viewNotification: "auth/viewNotification",
    }),

    closeNotification(notification, link = null) {
      if (notification?.id) {
        this.viewNotification(notification.id).then(() => {
          this.loadNotificationsRequest();

          if (link) {
            window.location.href = link;
          }
        });
      }

      this.setNotification(null);
    },

    loadNotificationsRequest() {
      this.loadNotifications().then(() => {
        if (!this.notifications || this.notifications?.is_showed) {
          return;
        }

        this.createNotification({
          id: this.notifications?.id || null,
          title: this.notifications?.title || "",
          button_link: this.notifications?.button_link || "",
          button_label: this.notifications?.button_label || "",
          closed: true,
          type: this.notifications?.type || "error",
          description: this.notifications?.description || "",
          group: this.notifications?.group || "",
        });
      });
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth < 1024;
    },
  },

  mounted() {
    this.locale = this.selectedLocale;
    window.addEventListener("resize", this.checkIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<template>
  <div class="main-layout">
    <MainHeader v-if="!isMobile" />
    <MobileHeader v-if="isMobile" />
    <div class="main-layout__body">
      <Aside />

      <div id="layoutBody" class="main-layout__content">
        <div v-if="$route.meta && $route.meta.title" class="main-layout__name">
          <h1>
            <span
              v-if="$route.meta.icon"
              class="main-layout__icon"
              :class="$route.meta.icon"
            />
            <span>{{ $route.meta.title }}</span>
          </h1>

          <MainSelect
            v-if="hasAnotherLocales && $route.meta.title === 'Баланс' && false"
            :options="locales"
            :selected-option="locale"
          />
        </div>

        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__notification-link {
    margin-top: 16px;
    text-decoration: none;
    color: $blue;
    @include body-1;
    display: block;
  }

  &__name {
    padding: 48px $space-xxl 64px;
    display: flex;
    align-items: center;

    h1 {
      @include title-2;
      margin-right: $space-s;
      display: flex;
      align-items: center;
      gap: $space-s;
    }

    @media (max-width: 900px) {
      padding: 40px 48px 32px;
    }

    @media (max-width: 768px) {
      padding: 40px 32px 32px;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;

    @media (max-width: 1024px) {
      width: 32px;
      height: 32px;
    }
  }

  &__body {
    display: flex;
    flex: 1;
    overflow: auto;
  }
  &__content {
    flex: 1;
    overflow: auto;
  }
}
.overflow-hidden {
  overflow: hidden;
}
</style>
