<script>
import Notification from "@/components/notification/Notification.vue";
import EmptyNotifications from "@/components/notification/EmptyNotifications.vue";
import EllipseViewAllButton from "@/components/notification/EllipseViewAllButton.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import { format, parseISO } from "date-fns";
import { ru } from "date-fns/locale";

export default {
  name: "NotificationsList",
  components: {
    MainButtonIcon,
    EllipseViewAllButton,
    EmptyNotifications,
    Notification,
  },
  props: {
    seenNotifications: {
      type: Array,
      required: true,
    },
    notSeenNotifications: {
      type: Array,
      required: true,
    },
    groupIconMap: {
      type: Map,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    intersect: {
      inserted(el, binding) {
        const { callback, once = false, options = { threshold: 0.5 } } =
          binding.value || {};

        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              callback?.();
              if (once) {
                observer.unobserve(el);
              }
            }
          });
        }, options);

        el._intersectionObserver = observer;
        observer.observe(el);
      },
      unbind(el) {
        if (el._intersectionObserver) {
          el._intersectionObserver.disconnect();
          delete el._intersectionObserver;
        }
      },
    },
  },
  methods: {
    getCurrentIconClass(group) {
      return this.groupIconMap.get(group) || null;
    },
    notificationSeen(notification) {
      if (!notification.isShowed) {
        this.$emit("notification-seen", notification.id);
      }
    },
    loadMoreIfNotLoading() {
      if (!this.isLoading) {
        this.$emit("load-more");
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    formatDate(dateString) {
      const date = parseISO(dateString);
      return format(date, "d MMMM, HH:mm", { locale: ru });
    },
  },
  computed: {
    isEmpty() {
      return (
        !this.notSeenNotifications.length && !this.seenNotifications.length
      );
    },
    getCurrentNotificationButtonSize() {
      return this.isMobile ? "m" : "s";
    },
  },
};
</script>

<template>
  <div class="notifications scrollable">
    <div v-if="isMobile" class="notifications-list__sub-header">
      <MainButtonIcon
        aria-label="Назад"
        class="notifications-list__sub-header__back-button"
        @click="$emit('close')"
      >
        <span class="icon-chevron-left"></span>
      </MainButtonIcon>
      <h2 class="notifications-list__sub-header__title">Уведомления</h2>
      <div class="notifications-list__sub-header__ellipse">
        <EllipseViewAllButton
          v-if="notSeenNotifications.length"
          @view-all="$emit('view-all')"
        />
      </div>
    </div>
    <div class="notifications-list scrollable">
      <div
        v-if="notSeenNotifications.length"
        class="notifications-list__not-view"
      >
        <div class="notifications-list__not-view__title">
          <h3>НОВЫЕ УВЕДОМЛЕНИЯ</h3>
          <EllipseViewAllButton
            v-if="!isMobile"
            @view-all="$emit('view-all')"
          />
        </div>
        <div
          v-for="notification of notSeenNotifications"
          :key="notification.id"
          v-intersect="{
            callback: () => notificationSeen(notification),
            once: true,
          }"
          class="notifications-list__not-view__notification"
        >
          <Notification
            :title="notification.title"
            :button-title="notification.buttonTitle"
            :button-link="notification.buttonLink"
            :description="notification.description"
            :date="formatDate(notification.date)"
            :icon-class="getCurrentIconClass(notification.group)"
            :button-size="getCurrentNotificationButtonSize"
            @onButtonClick="openLink(notification.buttonLink)"
          ></Notification>
        </div>
      </div>
      <div
        v-if="hasMore"
        v-intersect="{
          callback: loadMoreIfNotLoading,
          once: false,
        }"
        class="sentinel"
      ></div>

      <div class="notifications-list__view">
        <div
          v-if="seenNotifications.length"
          class="notifications-list__view__title"
        >
          <h3>ПРОСМОТРЕННЫЕ</h3>
        </div>
        <div
          v-for="notification of seenNotifications"
          :key="notification.id"
          class="notifications-list__view__notification"
        >
          <Notification
            :type="notification.type"
            :title="notification.title"
            :button-title="notification.buttonTitle"
            :button-link="notification.buttonLink"
            :description="notification.description"
            :date="formatDate(notification.date)"
            :icon-class="getCurrentIconClass(notification.group)"
            :button-size="getCurrentNotificationButtonSize"
            @onButtonClick="openLink(notification.buttonLink)"
          ></Notification>
        </div>
        <div
          v-if="hasMore"
          v-intersect="{
            callback: loadMoreIfNotLoading,
            once: false,
          }"
          class="sentinel"
        ></div>
      </div>
      <div v-if="isLoading" class="loader">
        <span class="icon-loader" />
      </div>
      <EmptyNotifications v-if="isEmpty && !isLoading" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.notifications {
  max-height: 70vh;
  overflow: auto;
  border-radius: 8px;
  @media (max-width: 576px) {
    max-height: 100vh;
    height: 100vh;
  }
}
.scrollable {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(1, 0, 30, 0.4);
    background-clip: content-box;
    border: 4px solid transparent;
  }
}
.notifications-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  @media (max-width: 576px) {
    max-height: unset;
    padding: 0;
    height: calc(100% - 56px);
    width: 100vw;
  }

  &__sub-header {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    &__back-button {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 8px;
      &:hover {
        background: #f3f3f3;
      }
    }
    .icon-chevron-left {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      background-color: #212121;
    }
    &__title {
      flex: 1 0 0;
      @include body-1-bold;
      color: #212121;
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
    }

    &__ellipse {
      min-width: 40px;
    }
  }
  &__not-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding-top: 8px;
    min-width: 520px;
    @media (max-width: 576px) {
      min-width: unset;
    }
    &__title {
      display: flex;
      height: 40px;
      padding: 8px 8px 8px 24px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      justify-content: space-between;

      > h3 {
        color: var(--text-dark-sixth, #969696);
        font-variant-numeric: lining-nums proportional-nums;
        @include caption-1-bold;
      }

      @media (max-width: 576px) {
        padding: 12px 24px;
        > h3 {
          @include text-2-bold;
        }
      }
    }
    &__notification {
      width: 100%;
    }
  }

  &__view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding-top: 8px;
    width: 100%;
    min-width: 520px;
    @media (max-width: 576px) {
      min-width: unset;
    }
    &__title {
      display: flex;
      height: 40px;
      padding: 8px 16px 8px 24px;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      > h3 {
        @include caption-1-bold;
        color: #969696;
        font-variant-numeric: lining-nums proportional-nums;
      }

      @media (max-width: 576px) {
        padding: 12px 24px;
        > h3 {
          @include text-2-bold;
        }
      }
    }
    &__notification {
      width: 100%;
    }
  }
}

.loader {
  display: flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.icon-loader {
  width: 24px;
  height: 24px;
  background-color: #212121;
}
</style>
