<script>
import QualificationPreview from "@/components/QualificationPreview.vue";
import TariffPreview from "@/components/TariffPreview.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "ProfileMenu",
  components: { MainButton, TariffPreview, QualificationPreview },
  props: {
    userQualificationCode: {
      type: String,
      required: true,
    },
    tariffCode: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    userRcBalance: {
      type: String,
      required: true,
    },
    userAvatar: {
      type: String,
      default: null,
      required: false,
    },
  },
};
</script>

<template>
  <div class="profile-menu">
    <div class="profile-menu__main-info">
      <img
        v-if="userAvatar"
        class="profile-menu__main-info__avatar"
        :src="userAvatar"
        alt="Фото профиля пользователя"
      />
      <img
        v-else
        class="profile-menu__main-info__avatar"
        src="../../assets/images/user_no_img.svg"
        alt="Фото профиля пользователя"
      />
      <div class="profile-menu__content">
        <div class="profile-menu__info">
          <div class="profile-menu__name">{{ userName }}</div>
          <MainButton
            class="profile-menu__rc"
            :title="`${userRcBalance} RC`"
            color="transparent"
            @click="$emit('balance-click')"
          >
            <template #iconLeft>
              <span class="icon-coin-2-fill" />
            </template>
          </MainButton>
        </div>
        <div class="profile-menu__status">
          <qualification-preview :qualification-code="userQualificationCode" />

          <tariff-preview
            class="profile-menu__tariff"
            :tariff-code="tariffCode"
          />
        </div>
      </div>
    </div>
    <div class="profile-menu__divider"></div>
    <div class="profile-menu__actions">
      <MainButton
        class="profile-menu__action-btn"
        title=" Редактировать профиль"
        color="transparent"
        @click="$emit('edit-profile-button-click')"
      >
        <template #iconLeft>
          <span class="icon icon-note-edit-thin" />
        </template>
      </MainButton>
      <MainButton
        class="profile-menu__action-btn logout"
        title="Выйти"
        color="transparent"
        @click="$emit('logout-button-click')"
      >
        <template #iconLeft>
          <span class="icon icon-exit-thin" />
        </template>
      </MainButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.profile-menu {
  display: flex;
  width: 440px;
  flex-direction: column;
  align-items: flex-start;
  &__main-info {
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    &__avatar {
      object-fit: cover;
      width: 44px;
      height: 44px;
      aspect-ratio: 1/1;
      border-radius: 44px;
    }
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
  }

  &__tariff {
    background-color: $light-second;
    margin-right: 4px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &__name {
    @include body-1-bold;
    color: var(--text-dark-primary, #212121);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &__rc {
    display: flex;
    gap: 4px;
    @include text-2;
    color: #474747;
    align-items: center;
    padding: 0;
    justify-content: flex-start;
    height: fit-content;
    .icon-coin-2-fill {
      width: 16px;
      height: 16px;
      background-color: #474747;
    }
    img {
      fill: black;
    }

    &:hover {
      cursor: pointer;
      color: #212121;
    }
  }
  &__status {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }

  &__divider {
    width: 440px;
    height: 1px;
    background: #e9e9e9;
  }
  &__actions {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  &__action-btn {
    justify-content: flex-start;
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    @include text-2;
    color: var(--text-dark-primary, #212121);
    font-variant-numeric: lining-nums proportional-nums;

    &.logout {
      color: #eb5757;
    }
    &:hover {
      background: var(--background-light-second, #f8f8f8);
    }
    > .icon {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;

      &.icon-exit-thin {
        background-color: #eb5757;
      }
      &.icon-note-edit-thin {
        background-color: #212121;
      }
    }
  }
}
</style>
