export const GROUP_NOTIFICATION_ICON_MAP = new Map([
  ["subscription_debtor", "icon-warning-regular"],
  ["subscription_1day", "icon-warning-regular"],
  ["subscription_3day", "icon-warning-regular"],
  ["activity_application_approved", "icon-circle-done-2"],
  ["activity_application_declined", "icon-circle-close"],
  ["company_booking_expires_week", "icon-tsp"],
  ["company_booking_expires_day", "icon-tsp"],
  ["system_notification", ""],
]);
