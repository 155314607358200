import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { ALLOWED_USERS } from "@/package/const/analytics-allowed-users";
import checkUserData from "@/package/helpers/check-user.helpers";
import checkUserBeforeEnter from "@/router/middleware/checkUserBeforeEnter";
import MainLayout from "../components/layouts/MainLayout";
import { ALLOWED_USERS_TSP } from "@/package/const/tsp-rating-allowed-users";
import { ALLOWED_USERS_TRANSACTION_SUPPORT } from "@/package/const/transaction-support-allowed-users";

Vue.use(VueRouter);

// TODO: проверка на доступность страниц для определенных тарифов

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          title: "Главная",
        },
      },
      {
        path: "balance",
        component: () => import("../components/layouts/RouteLayout"),
        children: [
          {
            path: "",
            name: "Balance",
            component: () => import("../views/balance/Balance"),
            meta: {
              title: "Баланс",
            },
          },
          {
            path: "output",
            name: "BalanceOutput",
            component: () => import("../views/balance/BalanceOutput"),
          },
          {
            path: "refill-successful",
            name: "BalanceRefillSuccessful",
            component: () =>
              import("../views/balance/BalanceRefillSuccessful.vue"),
          },
          {
            path: "refill-error",
            name: "BalanceRefillError",
            component: () => import("../views/balance/BalanceRefillError.vue"),
          },
          {
            path: "self-employed",
            name: "SelfEmployed",
            component: () => import("../views/balance/SelfEmployed.vue"),
          },
          // {
          //   path: "individual-entrepreneur",
          //   name: "IndividualEntrepreneur",
          //   component: () =>
          //     import("../views/balance/IndividualEntrepreneur.vue"),
          // },
        ],
      },
      {
        path: "transfers",
        component: () => import("../components/layouts/RouteLayout"),
        meta: {
          title: "Переводы",
        },
        children: [
          {
            path: "",
            name: "Transfers",
            component: () => import("../views/transfers/Transfers"),
            meta: {
              title: "Переводы",
            },
          },
          {
            path: "account",
            name: "TransferToAccount",
            component: () => import("../views/transfers/TransferToAccount"),
          },
          {
            path: "client",
            name: "TransferToClient",
            component: () => import("../views/transfers/TransferToClient"),
          },
        ],
      },
      {
        path: "analytics",
        name: "Analytics",
        component: () => import("../views/AnalyticsView.vue"),
        meta: {
          title: "Аналитика",
          allowedUsers: ALLOWED_USERS,
        },
      },
      {
        path: "tariffs",
        component: () => import("../components/layouts/RouteLayout"),
        children: [
          {
            path: "",
            name: "Tariffs",
            component: () => import("../views/tariff/Tariffs"),
          },
          {
            path: "buy",
            name: "TariffBuy",
            component: () => import("../views/tariff/TariffBuy"),
          },
          {
            path: "installment-success",
            name: "TariffInstallmentSuccess",
            component: () => import("../views/tariff/TariffInstallmentSuccess"),
          },
        ],
      },
      {
        path: "bonuses",
        name: "Bonuses",
        component: () => import("../views/Bonuses.vue"),
        meta: {
          title: "Бонусы",
        },
      },
      {
        path: "study",
        component: () => import("../components/layouts/RouteLayout"),
        children: [
          {
            path: "",
            name: "Study",
            component: () => import("../views/Study.vue"),
            meta: {
              title: "Обучение",
            },
          },
          {
            path: "test",
            name: "StudyTest",
            component: () => import("../views/StudyTest.vue"),
          },
          {
            path: "course",
            name: "Course",
            component: () => import("../views/CoorserCoursePage.vue"),
          },
        ],
      },
      {
        path: "study/:id",
        name: "CoursePage",
        component: () => import("../views/CoursePage.vue"),
      },
      {
        path: "news",
        name: "DistributorNews",
        component: () => import("../views/news/DistributorNews.vue"),
        meta: {
          title: "Новости",
        },
      },
      {
        path: "public-activities",
        name: "PublicActivitiesNews",
        component: () => import("../views/news/PublicActivitiesNews.vue"),
        meta: {
          title: "Общественная деятельность",
        },
      },
      {
        path: "news/:id",
        name: "News",
        component: () => import("../views/news/News"),
      },
      {
        path: "licenses",
        name: "Licenses",
        component: () => import("../views/Licenses.vue"),
        meta: {
          title: "Лицензии",
        },
      },
      {
        path: "referral-links",
        component: () => import("../components/layouts/RouteLayout"),
        children: [
          {
            path: "",
            name: "ReferralLinks",
            component: () => import("../views/referral/ReferralLinks"),
          },
        ],
      },
      {
        path: "qualifications",
        name: "Qualifications",
        component: () => import("../views/Qualifications"),
        meta: {
          title: "Квалификации",
        },
      },
      {
        path: "structure",
        name: "Structure",
        component: () => import("../views/Structure"),
        meta: {
          title: "Структура",
        },
      },
      {
        path: "subscription",
        name: "Subscription",
        component: () => import("../views/Subscription"),
        meta: {
          title: "Абонентская плата",
        },
      },
      {
        path: "connected-tsp",
        name: "ConnectedTSP",
        component: () => import("../views/ConnectedTSP"),
        meta: {
          title: "Подключенные ТСП",
          permittedTariffs: ["vip_d", "vip_gold_d"],
        },
      },
      {
        path: "transaction-support-tsp",
        component: () => import("../components/layouts/RouteLayout"),
        children: [
          {
            path: "",
            name: "TransactionSupportTsp",
            component: () =>
              import("../views/transaction-support/TransactionSupportTsp.vue"),
            meta: {
              title: "Сопровождение сделок с ТСП",
              // permittedTariffs: ["vip_d", "vip_gold_d"],
              allowedUsers: ALLOWED_USERS_TRANSACTION_SUPPORT,
            },
          },

          {
            path: "history-service",
            component: () => import("../components/layouts/RouteLayout"),
            children: [
              {
                path: "",
                name: "HistoryService",
                component: () =>
                  import("../views/transaction-support/HistoryService.vue"),
                meta: {
                  // permittedTariffs: ["vip_d", "vip_gold_d"],
                  allowedUsers: ALLOWED_USERS_TRANSACTION_SUPPORT,
                },
              },
              {
                path: ":id",
                name: "HistoryCurrentService",
                component: () =>
                  import(
                    "../views/transaction-support/HistoryCurrentService.vue"
                  ),
                meta: {
                  // permittedTariffs: ["vip_d", "vip_gold_d"],
                  allowedUsers: ALLOWED_USERS_TRANSACTION_SUPPORT,
                },
              },
            ],
          },

          {
            path: ":id",
            component: () => import("../components/layouts/RouteLayout"),
            children: [
              {
                path: "",
                name: "OtherServicesTsp",
                component: () =>
                  import("../views/transaction-support/ServicesTsp.vue"),
                meta: {
                  // permittedTariffs: ["vip_d", "vip_gold_d"],
                  allowedUsers: ALLOWED_USERS_TRANSACTION_SUPPORT,
                },
              },
              {
                path: "complete",
                name: "CompleteOrderService",
                component: () =>
                  import(
                    "../views/transaction-support/CompleteOrderService.vue"
                  ),
                meta: {
                  // permittedTariffs: ["vip_d", "vip_gold_d"],
                  allowedUsers: ALLOWED_USERS_TRANSACTION_SUPPORT,
                },
              },
            ],
          },
        ],
      },
      {
        path: "sales-plan",
        name: "SalesPlan",
        component: () => import("../views/sales-plan/SalesPlan.vue"),
        meta: {
          title: "План продаж",
          permittedTariffs: ["vip_d", "vip_gold_d"],
        },
      },
      {
        path: "sales-plan/add-organization",
        name: "AddOrganization",
        component: () =>
          import("../views/sales-plan/SalesPlanAddOrganization.vue"),
      },
      {
        path: "calculator-personal-gain",
        name: "CalculatorPersonalGain",
        component: () => import("../views/CalculatorPersonalGain"),
        meta: {
          title: "Калькулятор личной выгоды",
        },
      },
      {
        path: "financial-cycles",
        name: "FinancialCycles",
        component: () => import("../views/FinancialCycles"),
      },
      {
        path: "documents",
        name: "Documents",
        component: () => import("../views/documents/Documents"),
        meta: {
          title: "Документы",
        },
      },
      {
        path: "documents/:id",
        name: "DocumentsSection",
        component: () => import("../views/documents/DocumentsSection"),
      },
      {
        path: "certificate-order",
        name: "CertificateOrder",
        component: () => import("../views/documents/CertificateOrder.vue"),
        meta: {
          permittedTariffs: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
        },
      },
      {
        path: "calculator-tsp-income",
        name: "CalculatorTspIncome",
        component: () => import("../views/CalculatorTSPIncome"),
        meta: {
          title: "Калькулятор дохода от ТСП",
          permittedTariffs: ["vip_d", "vip_gold_d"],
        },
      },
      {
        path: "rating-distributors",
        name: "RatingDistributors",
        component: () => import("../views/RatingDistributors"),
        meta: {
          title: "Рейтинг дистрибьюторов",
        },
      },
      {
        path: "rating-tsp",
        name: "RatingTSP",
        component: () => import("../views/RatingTSP.vue"),
        meta: {
          title: "Рейтинг ТСП",
          permittedTariffs: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
        },
      },
      // // todo закоментировать
      // {
      //   path: "certificates",
      //   name: "Certificates",
      //   component: () => import("../views/CertificatesView.vue"),
      //   meta: {
      //     title: "Сертификаты",
      //     permittedTariffs: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
      //   },
      // },
      {
        path: "rc-house",
        name: "RC House",
        component: () => import("../views/RcHouse.vue"),
        meta: {
          title: "RC HOUSE",
          icon: "icon-logo-yellow-rc-house",
        },
      },
    ],
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../views/Registration"),
    beforeEnter: checkUserBeforeEnter,
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: () => import("../views/AgreementView.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior() {
    const LAYOUT_BODY = document.getElementById("layoutBody");

    if (LAYOUT_BODY) {
      LAYOUT_BODY.scrollTop = 0;
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let user = store.state.auth.user;

  function redirectUser(user) {
    if (!checkUserData(user) && to.path !== "/registration") {
      next("/registration");
    } else if (
      user?.agreements?.hasOwnProperty("dd_agreement") &&
      user?.agreements?.dd_agreement === false &&
      to.path !== "/agreement"
    ) {
      next("/agreement");
    } else if (
      (user?.agreements?.hasOwnProperty("dd_agreement") &&
        user?.agreements?.dd_agreement === true &&
        to.path === "/agreement") ||
      (!user?.agreements?.hasOwnProperty("dd_agreement") &&
        to.path === "/agreement")
    ) {
      next("/");
    } else if (to.query?.access_token || to.query?.refresh_token) {
      next("/");
    } else {
      if (to?.meta?.permittedTariffs) {
        const userTariffCode = user.tariff?.code;

        if (to.meta.permittedTariffs.includes(userTariffCode)) {
          return next();
        }

        return next("/");
      }

      if (to?.meta?.allowedUsers) {
        const userPhone = user.phone;

        if (to.meta.allowedUsers.includes(userPhone)) {
          return next();
        }

        return next("/");
      }

      next();
    }
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params.access_token) {
    // const decodedInfo = JSON.parse(atob(params.access_token.split(".")[1]));

    localStorage.setItem("token", params.access_token);
    // decodedInfo.exp * 1000 (miliseconds to seconds)
    // localStorage.setItem("expired", `${decodedInfo.exp * 1000}`);
  }

  if (params.refresh_token) {
    localStorage.setItem("refresh", params.refresh_token);
  }

  if (to.query?.code) {
    localStorage.setItem("code", to.query.code);
  }

  if (user === null || !localStorage.getItem("token")) {
    store
      .dispatch(
        "auth/checkUser",
        localStorage.getItem("code") ? localStorage.getItem("code") : ""
      )
      .then(() => {
        user = store.state.auth.user;
        localStorage.removeItem("code");

        redirectUser(user);
      });
  } else {
    redirectUser(user);
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
