/**
 * Хелпер для скачивания файла
 * @param {Blob} file - Файл для скачивания
 * @param {Object} config - объект настроек
 */
export default function downloadFileHelpers(
  file,
  config = { fileName: "file", type: "" }
) {
  const { fileName, type } = config;

  const href = URL.createObjectURL(file);
  const typeFile = type ?? file.type.split("/").at(-1);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${fileName}.${typeFile}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
