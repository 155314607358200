<script>
export default {
  name: "BadgeCounter",
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
  },
};
</script>

<template>
  <div class="counter-badge">
    <slot></slot>
    <span
      v-if="value"
      aria-label="Количество уведомлений"
      class="counter-badge__count"
      aria-hidden="true"
    >
      {{ value }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.counter-badge {
  &__count {
    position: absolute;
    right: 6px;
    top: 2px;
    border-radius: 16px;
    border: 1px solid #f8f8f8;
    background: #eb5757;
    @include caption-1-bold;
    height: 16px;
    min-width: 16px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    box-sizing: border-box;
    transform: translate(30%, 0%);
  }
}
</style>
