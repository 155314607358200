import API from "@/api";
// import store from "./index";

const state = {
  consultants: [],
  linksConsultants: null,
  isLoadingConsultants: true,

  currentConsultant: null,
  isLoadingCurrentConsultant: true,

  historyReceived: [],
  linksHistoryReceived: null,
  isLoadingHistoryReceived: false,

  historyProvided: [],
  linksHistoryProvided: null,
  isLoadingHistoryProvided: false,

  currentHistory: null,
  isLoadingCurrentHistory: false,

  isLoadingChangeStatus: false,

  services: [],
  isLoadingServices: false,
};

const getters = {};

const actions = {
  getConsultants({ commit, state }, filterOption) {
    let apiUrl = "api/bourse/consultants";
    let filter = "";

    if (filterOption) {
      commit("CLEAR_CONSULTANTS");

      if (filterOption !== "Все услуги") filter = filterOption;
    }

    if (state.linksConsultants) {
      if (state.linksConsultants.next) {
        apiUrl = state.linksConsultants.next;
      } else {
        return;
      }
    }

    commit("TOGGLE_LOADING_CONSULTANTS", true);

    const params = filter ? { service: filter } : {};

    return new Promise((resolve, reject) => {
      API.get(apiUrl, { params: params })
        .then((res) => {
          const { data, links } = res.data;

          commit("UPDATE_CONSULTANTS", {
            consultants: data,
            linksConsultants: links,
          });

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CONSULTANTS", false);
        });
    });
  },

  getCurrentConsultant({ commit }, id) {
    commit("TOGGLE_LOADING_CURRENT_CONSULTANT", true);

    return new Promise((resolve, reject) => {
      API.get(`api/bourse/consultants/${id}`)
        .then((res) => {
          commit("SET_CURRENT_CONSULTANT", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CURRENT_CONSULTANT", false);
        });
    });
  },

  sendRequestService(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("api/bourse/applications/received", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getHistoryReceived({ commit, state }, filterOption) {
    let apiUrl = "api/bourse/applications/received";
    let status = "";

    if (filterOption) {
      commit("CLEAR_HISTORY_RECEIVED");

      if (filterOption !== "any") status = filterOption;
    }

    if (state.linksHistoryReceived) {
      if (state.linksHistoryReceived.next) {
        apiUrl = state.linksHistoryReceived.next;
      } else {
        return;
      }
    }

    commit("TOGGLE_LOADING_HISTORY_RECEIVED", true);

    return new Promise((resolve, reject) => {
      API.get(apiUrl, { params: { status: status } || {} })
        .then((res) => {
          commit("UPDATE_HISTORY_RECEIVED", {
            historyReceived: res.data.data,
            linksHistoryReceived: res.data.links,
          });

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_HISTORY_RECEIVED", false);
        });
    });
  },

  getHistoryProvided({ commit, state }, filterOption) {
    let apiUrl = "api/bourse/applications/provided";
    let status = "";

    if (filterOption) {
      commit("CLEAR_HISTORY_PROVIDED");

      if (filterOption !== "any") status = filterOption;
    }

    if (state.linksHistoryProvided) {
      if (state.linksHistoryProvided.next) {
        apiUrl = state.linksHistoryProvided.next;
      } else {
        return;
      }
    }

    commit("TOGGLE_LOADING_HISTORY_PROVIDED", true);

    return new Promise((resolve, reject) => {
      API.get(apiUrl, { params: { status: status } || {} })
        .then((res) => {
          commit("UPDATE_HISTORY_PROVIDED", {
            historyProvided: res.data?.data,
            linksHistoryProvided: res.data?.links,
          });

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_HISTORY_PROVIDED", false);
        });
    });
  },

  getCurrentHistoryReceived({ commit, state }, payload) {
    commit("TOGGLE_LOADING_CURRENT_HISTORY", true);

    const foundIndex = state.historyReceived.findIndex(
      (item) => item.id === payload
    );

    return new Promise((resolve, reject) => {
      API.get(`api/bourse/applications/received/${payload}`)
        .then((res) => {
          commit("SET_CURRENT_HISTORY", res.data.data);

          if (foundIndex !== -1) {
            commit("SWAP_HISTORY_RECEIVED", {
              index: foundIndex,
              newItem: res.data.data,
            });
          }

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CURRENT_HISTORY", false);
        });
    });
  },

  getCurrentHistoryProvided({ commit }, payload) {
    commit("TOGGLE_LOADING_CURRENT_HISTORY", true);

    const foundIndex = state.historyProvided.findIndex(
      (item) => item.id === payload
    );

    return new Promise((resolve, reject) => {
      API.get(`api/bourse/applications/provided/${payload}`)
        .then((res) => {
          commit("SET_CURRENT_HISTORY", res.data.data);

          if (foundIndex !== -1) {
            commit("SWAP_HISTORY_PROVIDED", {
              index: foundIndex,
              newItem: res.data.data,
            });
          }

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CURRENT_HISTORY", false);
        });
    });
  },

  cancelReceived({ state, commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyReceived.findIndex(
      (item) => item.id === payload.id
    );

    const prevStatus = state.currentHistory.status.text;
    const newStatus = prevStatus === "in_work" ? "awaited_cancel" : "canceled";

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/received/${payload.id}/cancel`, {
        comment: payload.comment,
      })
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_RECEIVED", {
              index: foundItem,
              status: newStatus,
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  cancelProvided({ state, commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyProvided.findIndex(
      (item) => item.id === payload.id
    );

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/provided/${payload.id}/cancel`, {
        comment: payload.comment,
      })
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_PROVIDED", {
              index: foundItem,
              status: "canceled",
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  inworkProvided({ commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyProvided.findIndex(
      (item) => item.id === payload.id
    );

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/provided/${payload.id}/inwork`)
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_PROVIDED", {
              index: foundItem,
              status: "in_work",
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  approveReceived({ commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyProvided.findIndex(
      (item) => item.id === payload.id
    );

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/received/${payload.id}/approve`)
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_RECEIVED", {
              index: foundItem,
              status: "awaited_approve",
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  approveProvided({ commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyProvided.findIndex(
      (item) => item.id === payload.id
    );

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/provided/${payload.id}/approve`)
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_PROVIDED", {
              index: foundItem,
              status: "awaited_approve",
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  disputeProvided({ commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyProvided.findIndex(
      (item) => item.id === payload.id
    );

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/provided/${payload.id}/dispute`, {
        comment: payload.comment,
      })
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_PROVIDED", {
              index: foundItem,
              status: "awaited_check",
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  disputeReceived({ commit }, payload) {
    commit("TOGGLE_LOADING_CHANGE_STATUS", true);

    const foundItem = state.historyReceived.findIndex(
      (item) => item.id === payload.id
    );

    return new Promise((resolve, reject) => {
      API.put(`api/bourse/applications/received/${payload.id}/dispute`, {
        comment: payload.comment,
      })
        .then((res) => {
          if (foundItem !== -1) {
            commit("CHANGE_STATUS_ITEM_RECEIVED", {
              index: foundItem,
              status: "awaited_check",
            });
          }

          commit("CHANGE_STATUS_RECEIVED", res.data.data);

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_CHANGE_STATUS", false);
        });
    });
  },

  getFiltersService({ commit }) {
    commit("TOGGLE_LOADING_SERVICES", true);

    return new Promise((resolve, reject) => {
      API.get("api/bourse/consultants/services")
        .then((res) => {
          commit("SET_SERVICES", Object.values(res.data.data));

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit("TOGGLE_LOADING_SERVICES", false);
        });
    });
  },

  // getNextConsultants({ state, commit }) {
  //   if (!state.linksConsultants.next) return;
  //
  //   commit("TOGGLE_LOADING_CONSULTANTS", true);
  //
  //   return new Promise((resolve, reject) => {
  //     API.get(state.linksConsultants.next)
  //       .then((res) => {
  //         const { data, links } = res.data;
  //
  //         commit("UPDATE_CONSULTANTS", {
  //           consultants: data,
  //           linksConsultants: links,
  //         });
  //
  //         resolve(res.data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       })
  //       .finally(() => {
  //         commit("TOGGLE_LOADING_CONSULTANTS", false);
  //       });
  //   });
  // },
};

const mutations = {
  UPDATE_CONSULTANTS(state, payload) {
    state.consultants.push(...payload.consultants);
    state.linksConsultants = payload.linksConsultants;
  },

  CLEAR_CONSULTANTS(state) {
    state.consultants = [];
    state.linksConsultants = null;
  },

  UPDATE_HISTORY_RECEIVED(state, payload) {
    state.historyReceived.push(...payload.historyReceived);
    state.linksHistoryReceived = payload.linksHistoryReceived;
  },

  CLEAR_HISTORY_RECEIVED(state) {
    state.historyReceived = [];
    state.linksHistoryReceived = null;
  },

  UPDATE_HISTORY_PROVIDED(state, payload) {
    state.historyProvided.push(...payload.historyProvided);
    state.linksHistoryProvided = payload.linksHistoryProvided;
  },

  CLEAR_HISTORY_PROVIDED(state) {
    state.historyProvided = [];
    state.linksHistoryProvided = null;
  },

  SET_CURRENT_CONSULTANT(state, payload) {
    state.currentConsultant = payload;
  },

  TOGGLE_LOADING_CONSULTANTS(state, payload) {
    state.isLoadingConsultants = payload ?? !state.isLoadingConsultants;
  },

  TOGGLE_LOADING_CURRENT_CONSULTANT(state, payload) {
    state.isLoadingCurrentConsultant =
      payload ?? !state.isLoadingCurrentConsultant;
  },

  TOGGLE_LOADING_HISTORY_RECEIVED(state, payload) {
    state.isLoadingHistoryReceived = payload ?? !state.isLoadingHistoryReceived;
  },

  TOGGLE_LOADING_HISTORY_PROVIDED(state, payload) {
    state.isLoadingHistoryProvided = payload ?? !state.isLoadingHistoryProvided;
  },

  TOGGLE_LOADING_CURRENT_HISTORY(state, payload) {
    state.isLoadingCurrentHistory = payload ?? !state.isLoadingCurrentHistory;
  },

  SET_CURRENT_HISTORY(state, payload) {
    state.currentHistory = payload;
  },

  CHANGE_STATUS_ITEM_RECEIVED(state, payload) {
    state.historyReceived[payload.index].status.text = payload.status;
  },

  CHANGE_STATUS_ITEM_PROVIDED(state, payload) {
    state.historyProvided[payload.index].status.text = payload.status;
  },

  CHANGE_STATUS_RECEIVED(state, payload) {
    state.currentHistory = payload;
  },

  TOGGLE_LOADING_CHANGE_STATUS(state, payload) {
    state.isLoadingChangeStatus = payload ?? !state.isLoadingChangeStatus;
  },

  SWAP_HISTORY_RECEIVED(state, payload) {
    state.historyReceived[payload.index] = payload.newItem;
  },

  SWAP_HISTORY_PROVIDED(state, payload) {
    state.historyProvided[payload.index] = payload.newItem;
  },

  SET_SERVICES(state, payload) {
    state.services = payload;
  },

  TOGGLE_LOADING_SERVICES(state, payload) {
    state.isLoadingServices = payload ?? !state.isLoadingServices;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
