<script>
import ProfileMenu from "@/components/main-header/ProfileMenu.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import PopoverButton from "@/components/PopoverButton.vue";
import NotificationWidget from "@/components/notification/NotificationWidget.vue";

export default {
  name: "MainHeader",
  components: {
    NotificationWidget,
    PopoverButton,
    ProfileMenu,
  },

  data() {
    return {
      isProfilePopupOpen: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),
    ...mapGetters({
      getUserDistributorAccount: "auth/getUserDistributorAccount",
    }),

    userQualificationCode() {
      return this.user?.qualification?.code ? this.user.qualification.code : "";
    },
    formattedName() {
      return `${this.user.last_name} ${this.user.first_name}`;
    },
    formattedSummaryActive() {
      return this.getUserDistributorAccount.summary_active.toLocaleString(
        "ru-RU"
      );
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    openSettingsPage() {
      window.open(
        `${process.env.VUE_APP_PASSPORT_URL}profile?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`,
        "_blank"
      );
      this.toggleProfilePopup();
    },

    openBalancePage() {
      this.$router.push("/balance");
      this.toggleProfilePopup();
    },
    logoutRequest() {
      this.toggleProfilePopup();
      this.logout().then(() => {
        location.href = process.env.VUE_APP_FRONT_URL;
      });
    },
    toggleProfilePopup() {
      this.isProfilePopupOpen = !this.isProfilePopupOpen;
    },
  },
};
</script>

<template>
  <header class="main-header">
    <div class="main-header__logo">
      <img src="../../assets/images/logo_rc_office.svg" alt="" />
    </div>

    <div class="main-header__title title">
      <div v-if="selectedBinaryAccount" class="title__links">
        <router-link
          exact-active-class="title__links_active"
          :to="{ name: 'ReferralLinks' }"
        >
          <img src="./../../assets/icons/referral_links.svg" alt="" />
          Реферальные ссылки
        </router-link>
      </div>

      <span v-if="selectedBinaryAccount" class="divider"></span>
      <NotificationWidget class="main-header__title notification" />
      <PopoverButton
        :is-popup-open="isProfilePopupOpen"
        button-aria-label="Открыть профиль"
        @togglePopup="toggleProfilePopup"
      >
        <template #default>
          <div class="profile-button__img">
            <img
              v-if="user.avatar"
              :src="user.avatar"
              alt="иконка фото профиля"
            />
            <img
              v-else
              src="../../assets/images/user_no_img.svg"
              alt="иконка фото профиля"
            />
          </div>
        </template>
        <template #content>
          <ProfileMenu
            :user-qualification-code="userQualificationCode"
            :tariff-code="user?.tariff?.code || ''"
            :user-rc-balance="formattedSummaryActive"
            :user-name="formattedName"
            :user-avatar="user?.avatar"
            @edit-profile-button-click="openSettingsPage"
            @logout-button-click="logoutRequest"
            @balance-click="openBalancePage"
          />
        </template>
      </PopoverButton>
    </div>
  </header>
</template>

<style scoped lang="scss">
.main-header {
  display: flex;
  justify-content: space-between;
  height: 72px;
  padding: 16px 24px;
  border-bottom: 1px solid var(--outline-light, rgba(33, 33, 33, 0.1));
  background: var(--background-light-primary, #fff);
  gap: 20px;
  &__logo {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      cursor: pointer;

      > img {
        filter: invert(1);
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;

    @media (max-width: 1023px) {
      display: none;
    }

    .divider {
      width: 1px;
      height: 24px;
      margin: 0 20px;
      background: #e9e9e9;
    }
    .notification {
      margin-right: 16px;
    }
  }

  .title {
    &__notification {
      padding: 2px 24px 2px 0;
      border-right: 1px solid $light-fifth;

      button {
        width: 24px;
        height: 24px;
        background: url("./../../assets/icons/notifications.svg");
        background-size: contain;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    &__links {
      position: relative;
      @include text-2;
      line-height: 1;

      &_active {
        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $dark-primary;
          position: absolute;
          bottom: -4px;
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $dark-primary;

        img {
          margin-right: $space-s;
        }
      }
    }
  }
}
.profile-button {
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      aspect-ratio: 1 / 1;
      border-radius: 32px;
      object-fit: cover;
    }
  }
}
</style>
