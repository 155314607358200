<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { MENU_ITEMS } from "@/package/const/menu-items";
import QualificationPreview from "@/components/qualifications/QualificationPreview.vue";
import AsideProfile from "@/components/aside/AsideProfile.vue";

export default {
  components: {
    AsideProfile,
    QualificationPreview,
  },

  data() {
    return {
      windowWidth: window.innerWidth,

      menuItems: MENU_ITEMS,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showMenu: (state) => state.showMenu,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    showQualificationPreviewTitle() {
      return !!this.user.qualification;
    },
    userQualification() {
      return this.user.qualification
        ? {
            code: this.user.qualification.code,
            name: this.user.qualification.name,
          }
        : { code: "without_qualification", name: "У вас нет квалификации" };
    },
    isMobile() {
      return this.windowWidth <= 1023;
    },
  },

  methods: {
    ...mapMutations({
      setShowMenu: "SET_SHOW_MENU",
    }),

    ...mapActions({
      logout: "auth/logout",
    }),

    logoutRequest() {
      this.logout().then(() => {
        location.href = process.env.VUE_APP_FRONT_URL;
      });
    },

    checkAllowedTariffCodes(item) {
      return item?.allowedTariffCodes
        ? item.allowedTariffCodes.includes(this.user?.tariff?.code)
        : true;
    },

    checkAllowedUsers(item) {
      return item?.allowedUsers
        ? item.allowedUsers.includes(this.user?.phone)
        : true;
    },

    showRouterLink(menuItem) {
      return this.checkAllowedTariffCodes(menuItem);
    },

    showMenuItem(menuItem) {
      return menuItem?.nestedItems && this.checkAllowedTariffCodes(menuItem);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    hideMenu() {
      if (this.windowWidth <= 1023) {
        this.setShowMenu(false);
      }
    },

    sortedNestedItems(nestedItems) {
      return nestedItems.filter((item) => {
        return (
          this.checkAllowedTariffCodes(item) && this.checkAllowedUsers(item)
        );
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    if (!this.selectedBinaryAccount) {
      const bonusesItemIndex = this.menuItems.findIndex((item) => {
        return item.title === "Бонусы";
      });

      this.menuItems.splice(bonusesItemIndex, 1);
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<template>
  <div class="aside" :class="{ aside_opened: showMenu }">
    <div v-if="isMobile" class="aside__logo">
      <img src="../../assets/images/logo_rc_office.svg" alt="лого компании" />
      <button @click="setShowMenu(false)">
        <img
          src="../../assets/icons/close.svg"
          alt="иконка скрыть боковую панель"
        />
      </button>
    </div>
    <AsideProfile v-if="isMobile" />
    <div v-if="!isMobile" class="aside__qualification">
      <QualificationPreview
        :title="showQualificationPreviewTitle"
        :qualification="userQualification"
        @click.native="hideMenu"
      />
    </div>

    <nav class="aside__menu menu">
      <div v-for="menuItem in menuItems" :key="menuItem.title">
        <template v-if="showMenuItem(menuItem) && menuItem.title !== 'Помощь'">
          <div
            class="menu__item"
            :class="menuItem.showNestedItems ? 'menu__item_opened' : ''"
            @click="menuItem.showNestedItems = !menuItem.showNestedItems"
          >
            <img :src="menuItem.icon" :alt="menuItem.title" />
            {{ menuItem.title }}
            <svg
              class="arrow"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.49997 10.2803C7.35353 10.1339 7.35353 9.89642 7.49997 9.74997C7.64642 9.60352 7.88386 9.60353 8.0303 9.74997L11.8674 13.5871C11.9406 13.6603 12.0593 13.6603 12.1326 13.5871L15.9696 9.74997C16.1161 9.60353 16.3535 9.60353 16.5 9.74997C16.6464 9.89642 16.6464 10.1339 16.5 10.2803L12.2651 14.5151C12.1187 14.6616 11.8813 14.6616 11.7348 14.5151L7.49997 10.2803Z"
                fill="#212121"
              />
            </svg>
          </div>

          <div
            class="menu submenu"
            :class="menuItem.showNestedItems ? 'submenu_opened' : ''"
          >
            <router-link
              v-for="nestedItem in sortedNestedItems(menuItem.nestedItems)"
              :key="nestedItem.href"
              class="menu__item"
              exact-active-class="menu__item_active"
              :to="{ path: nestedItem.href }"
              @click.native="hideMenu"
            >
              {{ nestedItem.title }}
            </router-link>
          </div>
        </template>

        <template v-else-if="menuItem.title === 'Помощь'">
          <a class="menu__item" :href="menuItem.href" target="_blank">
            <img :src="menuItem.icon" :alt="menuItem.title" />
            {{ menuItem.title }}
          </a>
        </template>

        <template v-else-if="showRouterLink(menuItem)">
          <router-link
            class="menu__item"
            exact-active-class="menu__item_active"
            :to="{ path: menuItem.href }"
            @click.native="hideMenu"
          >
            <img :src="menuItem.icon" :alt="menuItem.title" />
            {{ menuItem.title }}
          </router-link>
        </template>
      </div>

      <button v-if="isMobile" class="menu__logout" @click="logoutRequest">
        Выйти
      </button>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.aside {
  width: 404px;
  background-color: $light-primary;
  border-right: 1px solid $outline-light;
  overflow: hidden;

  &__qualification {
    padding: 16px;
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-xl $space-m 0;
    margin-left: 24px;

    @media (max-width: 1024px) {
      padding: $space-l $space-l 0;
      margin-left: 0;
    }

    button {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      cursor: pointer;

      > img {
        filter: invert(1);
      }
    }
  }

  &__menu {
    margin: 0;
    list-style: none;
    overflow: auto;
    height: calc(100dvh - 186px);

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $light-sixth;
      border-radius: 8px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }

  .menu {
    &__logout {
      border: none;
      background-color: transparent;
      cursor: pointer;
      @include body-1;
      color: $dark-fifth;
      padding: 16px 48px;

      &:hover {
        color: $dark-third;
      }

      &:active {
        color: $dark-primary;
      }
    }

    .submenu {
      padding-left: 24px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s linear;

      &_opened {
        overflow: visible;
        max-height: 500px;
        animation: hide-scroll 0.25s backwards;

        @keyframes hide-scroll {
          from,
          to {
            overflow: hidden;
          }
        }
      }

      .menu__item {
        &:after {
          width: 100%;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 16px 24px;
      @include body-1;
      color: $dark-primary;
      position: relative;
      z-index: 1;
      cursor: pointer;
      text-decoration: none;

      > img {
        filter: invert(100%);
      }

      &_active {
        &:after {
          background: linear-gradient(
            90deg,
            rgba(33, 33, 33, 0.08) 0%,
            rgba(33, 33, 33, 0) 100%
          );
          opacity: 1 !important;
        }

        &:hover {
          &:after {
            background: linear-gradient(
              90deg,
              rgba(33, 33, 33, 0.04) 0%,
              rgba(33, 33, 33, 0) 100%
            ) !important;
          }
        }
      }

      .arrow {
        margin-left: auto;
        justify-self: flex-end;
        transition: transform 0.2s ease-in-out;
      }

      &_opened {
        svg {
          transform: rotate(-180deg);
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-size: 100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover {
        &:after {
          background: linear-gradient(
            90deg,
            rgba(33, 33, 33, 0.04) 0%,
            rgba(33, 33, 33, 0) 100%
          );
          opacity: 1;
        }
      }

      &:active {
        color: $dark-primary;

        svg {
          path {
            fill: $dark-primary;
          }
        }

        &:after {
          opacity: 1;
          background: linear-gradient(
            90deg,
            rgba(33, 33, 33, 0.08) 0%,
            rgba(33, 33, 33, 0) 100%
          );
        }
      }
    }
  }

  @media (max-width: 1440px) {
    width: 330px;
  }

  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    z-index: 999999;
    height: 100dvh;
    max-width: 425px;
    width: 100%;
    left: -100%;
    transition: left 0.4s ease;

    &__menu {
      height: calc(100dvh - 318px);
    }
  }

  &_opened {
    left: 0;
  }
}
</style>
