<script>
import { mapState, mapMutations } from "vuex";
import NotificationWidget from "@/components/notification/NotificationWidget.vue";

export default {
  components: { NotificationWidget },
  computed: {
    ...mapState({
      showMenu: (state) => state.showMenu,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),
  },

  methods: {
    ...mapMutations({
      setShowMenu: "SET_SHOW_MENU",
    }),
  },
};
</script>

<template>
  <header class="mobile-header">
    <div class="mobile-header__menu" @click="setShowMenu(true)">
      <img src="../assets/icons/menu.svg" alt="" />
    </div>
    <div v-if="selectedBinaryAccount" class="divider"></div>
    <div v-if="selectedBinaryAccount" class="mobile-header__links">
      <router-link
        exact-active-class="mobile-header__links_active"
        :to="{ name: 'ReferralLinks' }"
      >
        <img src="../assets/icons/referral_links.svg" alt="" />
        Реферальные ссылки
      </router-link>
    </div>
    <NotificationWidget class="mobile-header__notifications" />
  </header>
</template>

<style scoped lang="scss">
.mobile-header {
  width: 100%;
  padding: 16px 0;
  background-color: $background-grey;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }

  &__menu {
    padding: 0 24px;
    cursor: pointer;
    height: 34px;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__links {
    position: relative;
    margin-left: 24px;
    @include body-1;
    line-height: 1;

    &_active {
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $dark-primary;
        position: absolute;
        bottom: -4px;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $dark-primary;

      img {
        margin-right: $space-s;
      }
    }
  }

  &__notifications {
    margin-right: 8px;
    margin-left: auto;
  }
}
.divider {
  width: 1px;
  height: 24px;
  background-color: #e9e9e9;
}
</style>
