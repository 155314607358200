<script>
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

export default {
  name: "PopoverButton",
  components: { MainButtonIcon },
  props: {
    isPopupOpen: {
      type: Boolean,
      required: true,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    buttonAriaLabel: {
      type: String,
      default: "",
    },
  },
  watch: {
    isPopupOpen(newVal) {
      const body = document.getElementById("layoutBody");
      if (newVal) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
  },
  beforeDestroy() {
    const body = document.getElementById("layoutBody");
    body.classList.remove("overflow-hidden");
  },
};
</script>

<template>
  <div class="popover-button-wrapper">
    <MainButtonIcon
      :class="{
        'popover-button': true,
        active: isPopupOpen,
      }"
      :aria-label="buttonAriaLabel"
      @click="$emit('togglePopup')"
    >
      <slot></slot>
    </MainButtonIcon>
    <transition name="popup">
      <div
        v-if="isPopupOpen"
        :class="['popover-popup', { 'popover-fullscreen': isFullScreen }]"
      >
        <slot name="content"></slot>
      </div>
    </transition>

    <div
      v-if="isPopupOpen"
      class="popover-overlay"
      @click="$emit('togglePopup')"
    ></div>
  </div>
</template>

<style scoped lang="scss">
.popover-button-wrapper {
  position: relative;
  display: inline-block;
}

.active {
  background: #f3f3f3;
}

.popover-button {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;

  &:hover {
    background: var(--background-light-third, #f3f3f3);
    cursor: pointer;
    user-select: none;
  }
}

.popover-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 990;
  margin-top: 4px;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 25px 0 rgba(33, 33, 33, 0.1);
}

.popover-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 5;
}

.popup-enter-active,
.popup-leave-active {
  transition: all 0.2s ease-out;
}
.popup-enter,
.popup-leave-to {
  opacity: 0;
}

.popover-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin-top: 0;
  border-radius: 0;
  z-index: 999;
  background: #fff;
}
</style>
