<script>
import NotificationsList from "@/components/notification/NotificationsList.vue";
import PopoverButton from "@/components/PopoverButton.vue";
import BadgeCounter from "@/components/notification/BadgeCounter.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { GROUP_NOTIFICATION_ICON_MAP } from "@/components/notification/groupNotificationsParametersMap";

export default {
  name: "NotificationWidget",
  components: {
    NotificationsList,
    BadgeCounter,
    PopoverButton,
  },
  data() {
    return {
      isNotificationPopupOpen: false,
      groupNotificationIconMap: GROUP_NOTIFICATION_ICON_MAP,
      isMobile: false,
    };
  },
  computed: {
    ...mapState("notifications", {
      getSeenNotifications: (state) => state.seenNotifications,
      getNotSeenNotifications: (state) => state.notSeenNotifications,
      getIsLoading: (state) => state.isLoading,
      getLastPage: (state) => state.lastPage,
      getTotalNotView: (state) => state.totalNotView,
      seenNotifications: (state) => state.seenNotifications,
    }),
    getNotificationsNotViewCount() {
      return this.getTotalNotView;
    },
    hasMoreNotifications() {
      return this.getLastPage !== 1;
    },
  },
  methods: {
    ...mapMutations({
      setPage: "notifications/SET_PAGE",
    }),
    ...mapActions({
      markAsReadAll: "notifications/markAsReadAll",
      getAllNotifications: "notifications/getAllNotifications",
      markOneAsRead: "notifications/markAsRead",
      markNotification: "notifications/markNotification",
      markNotificationsAsSeen: "notifications/markNotificationsAsSeen",
      fetchTotalNotViewCount: "notifications/fetchTotalNotViewCount",
    }),
    toggleNotificationPopup() {
      if (this.isNotificationPopupOpen) {
        this.setMarkedNotificationsSeen();
      }
      this.isNotificationPopupOpen = !this.isNotificationPopupOpen;
    },
    viewAll() {
      this.markAsReadAll();
    },
    handleNotificationSeen(notificationId) {
      this.markNotification(notificationId);
    },
    async setMarkedNotificationsSeen() {
      await this.markNotificationsAsSeen();
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth < 576;
    },
    async loadMore() {
      if (!this.getIsLoading) {
        await this.getAllNotifications();
      }
    },
  },
  async mounted() {
    this.checkIsMobile();
    window.addEventListener("resize", this.checkIsMobile);
    await this.fetchTotalNotViewCount();
    await this.loadMore();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<template>
  <PopoverButton
    :is-popup-open="isNotificationPopupOpen"
    :is-full-screen="isMobile"
    button-aria-label="Открыть список уведомлений"
    @togglePopup="toggleNotificationPopup"
  >
    <template #default>
      <BadgeCounter :value="getNotificationsNotViewCount">
        <span class="icon-notification-thin"></span>
      </BadgeCounter>
    </template>
    <template #content>
      <NotificationsList
        :not-seen-notifications="getNotSeenNotifications"
        :seen-notifications="getSeenNotifications"
        :group-icon-map="groupNotificationIconMap"
        :is-loading="getIsLoading"
        :has-more="hasMoreNotifications"
        :is-mobile="isMobile"
        @notification-seen="handleNotificationSeen"
        @view-all="viewAll"
        @load-more="loadMore"
        @close="toggleNotificationPopup"
      />
    </template>
  </PopoverButton>
</template>

<style scoped lang="scss">
.icon-notification-thin {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: #212121;
}
</style>
